<template>
  <v-text-field
    class="c-input-xs"
    :class="{ 'c-input-has-sort': hasSort }"
    v-model="value"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="sortPrependIcon"
    @click:prepend-inner="sortClick"
    @keyup.enter="onFilter"
    dense
    outlined
    clearable
    hide-details
  ></v-text-field>
</template>

<script>
import { debounce } from "@/libs/helpers";

export default {
  name: "InputFilter",
  props: {
    hasSort: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => "",
    },
    sortName: {
      type: String,
      default: () => "",
    },
    sorting: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    defaultValue: {
      type: [Number, String],
      default: () => null,
    },
  },
  data: () => ({
    value: null,
    sortValue: null,
  }),
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return "";
      }
      switch (this.sortValue) {
        case "asc":
          return "mdi-arrow-up";
        case "desc":
          return "mdi-arrow-down";
        default:
          return "mdi-arrow-up-down";
      }
    },
  },
  watch: {
    value() {
      this.onFilter();
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null;
      }
    },
  },
  created() {
    if (this.defaultValue) {
      this.value = this.defaultValue;
    }
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit("onFilter", {
        name: this.name,
        value: this.value,
      });
    }, 500),
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = "asc";
      } else if (this.sortValue === "asc") {
        this.sortValue = "desc";
      } else if (this.sortValue === "desc") {
        this.sortValue = "asc";
      }
      this.$emit("onSort", {
        sort_by: this.sortName,
        sort_type: this.sortValue,
      });
    },
  },
};
</script>
